import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import APIResponse from '../Interfaces/APIResponse';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'absolute',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }),
);

interface TestDeleteDialogProps {
    suiteID: number;
    testID: string;
    testName: string;
    onDeleteTest: () => Promise<APIResponse>
}

export default function TestDeleteDialog(props: TestDeleteDialogProps) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleDelete = async () => {
        setOpen(false);
        await props.onDeleteTest()        
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteForeverIcon />}
                onClick={(event) => { event.stopPropagation(); handleOpen() }}
            >
                Delete
            </Button>
            <Modal
                open={open}
                onBackdropClick={(event) => { event.stopPropagation(); handleClose() }}
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <h2>Delete Test {props.testName}</h2>
                    <p>Are you sure you wish to delete this test?</p>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteForeverIcon />}
                        onClick={(event) => { event.stopPropagation(); handleDelete() }}
                    >
                        Delete
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CancelIcon />}
                        onClick={(event) => { event.stopPropagation(); handleClose() }}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>            
        </div>
    );
}