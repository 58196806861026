import Button from "@material-ui/core/Button";
import React from "react";
import { Link } from "react-router-dom";

export default function ErrorPage() {
    return (
        <div>
            <h1>An error has occured</h1> 
            <Button variant="contained" component={Link} to="/">Return to test list</Button>
        </div>
    );
}