import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme, FormControlLabel, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { ResultValue } from '../Enums/ResultValue';
import APIResponse from '../Interfaces/APIResponse';
import Test from '../Interfaces/Test';
import TestDeleteDialog from './TestDeleteDialog';
import TestPanelDetails from './TestPanelDetails';
import TestPanelResults from './TestPanelResults';
import TestPanelSettings from './TestPanelSettings';
import ResultPanel from '../ResultPanel';
import { FileType } from '../Enums/FileType';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
    }),
);

interface TestPanelProps {
    testDetails: Test,
    onChange: (test: Test) => Promise<void>
    onDelete: (test: Test) => Promise<APIResponse>
    onSuccessfulUpload: (test: Test) => Promise<void>
    onRunTest: (test: Test) => Promise<void>
    allRunDisabled: boolean
}

export default function TestPanel(props: TestPanelProps) {
    const classes = useStyles();

    const [testOutcome, setTestOutcome] = useState(ResultValue.Unknown);
    const [expectedOutcome, setExpectedOutcome] = useState(props.testDetails.expectedResult);
    const [comparison, setComparison] = useState(props.testDetails.isComparison);
    const [runDisabled, setRunDisabled] = useState(false);

    const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        props.testDetails.testName = (event.target.value as string);
        props.onChange(props.testDetails);
    }

    const handleGTINChange = (gtin: string) => {
        props.testDetails.gtin = gtin;
        props.onChange(props.testDetails);
    }

    const handleCompareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.testDetails.isComparison = event.target.checked;
        setComparison(event.target.checked);
        props.onChange(props.testDetails);
    }

    const handleExpectedResultChange = async (expectedResult: ResultValue) => {
        props.testDetails.expectedResult = expectedResult;
        setExpectedOutcome(expectedResult);
        props.onChange(props.testDetails);
    }

    const handleSourceFileTypeChange = async (sourceFileType: FileType) =>{
        props.testDetails.sourceType = sourceFileType;
        props.onChange(props.testDetails);
    }

    const handleExpectedFileTypeChange = async (expectedFileType: FileType) =>{
        props.testDetails.outputType = expectedFileType;
        props.onChange(props.testDetails);
    }

    const handleDeleteTest = (): Promise<APIResponse> => {
        return props.onDelete(props.testDetails);
    }

    const getTestResult = async (test: Test) => {
        setRunDisabled(true);
        try {
            await props.onRunTest(test);
        }
        finally {
            setRunDisabled(false);
        }
    }

    const calculateTestOutcome = (expectedOutcome: ResultValue, actualOutcome: ResultValue) => {
        if (actualOutcome === ResultValue.Unknown) {
            setTestOutcome(actualOutcome);
        } else {
            var pass = expectedOutcome === actualOutcome;
            pass ? setTestOutcome(ResultValue.Pass) : setTestOutcome(ResultValue.Fail)
        }
    }

    useEffect(() => {
        calculateTestOutcome(props.testDetails.expectedResult, props.testDetails.actualResult);
    }, [props.testDetails.expectedResult, props.testDetails.actualResult])

    return (
        <Accordion style={{ width: "100%" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container alignItems="center" spacing={3} style={{ width: "100%" }}>
                    <Grid item xs={2}>
                        <TextField
                            label="Scenario Test Name"
                            defaultValue={props.testDetails.testName}
                            id={"name_" + props.testDetails.testID}
                            variant="outlined"
                            size="small"
                            onClick={(event) => event.stopPropagation()}
                            onBlur={handleNameChange}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={comparison}
                                    color="primary"
                                    onClick={(event) => event.stopPropagation()}
                                    onChange={handleCompareChange}
                                />
                            }
                            label="Compare"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<PlayArrowIcon />}
                            onClick={(event) => { event.stopPropagation(); getTestResult(props.testDetails); }}
                            disabled={props.allRunDisabled || runDisabled}
                        >
                            Run
                    </Button>
                    </Grid>
                    <Grid item xs={1}>
                        {props.testDetails.lastRunDate ? "Last Run: " + new Date(props.testDetails.lastRunDate).toLocaleString() : ""}
                    </Grid>
                    <Grid item xs={5}>
                    </Grid>
                    <Grid item xs={1}>
                        <ResultPanel success={testOutcome} />
                    </Grid>
                    <Grid>
                        <TestDeleteDialog
                            suiteID={props.testDetails.suiteID}
                            testID={props.testDetails.testID}
                            testName={props.testDetails.testName}
                            onDeleteTest={handleDeleteTest} />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Paper style={{ width: "100%" }}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Grid container spacing={3} style={{ width: "100%" }}>
                            <Grid item xs={2}>
                                <TestPanelDetails
                                    testID={props.testDetails.testID}
                                    gtin={props.testDetails.gtin}
                                    expectedResult={expectedOutcome}
                                    onGTINChange={handleGTINChange}
                                    onExpectedResultChange={handleExpectedResultChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TestPanelSettings
                                    testDetails={props.testDetails}
                                    onSuccessfulUpload={props.onSuccessfulUpload}
                                    onSourceTypeChange={handleSourceFileTypeChange}
                                    onExpectedTypeChange={handleExpectedFileTypeChange}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TestPanelResults
                                    suiteID={1}
                                    testID={props.testDetails.testID}
                                    results={props.testDetails.testResults}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </AccordionDetails>
        </Accordion>
    )
}
