import React, { useRef } from 'react'
import Dropzone, { FileWithPath } from 'react-dropzone'
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp'
import { Color } from '@material-ui/lab/Alert';
import { FileUse } from '../Enums/FileUse';
import { uploadFile, translateFile } from '../Handlers/APIHandler';
import FileData from '../Interfaces/FileData';
import Test from '../Interfaces/Test';
import Snack, { SnackRef } from '../Snack';
import { FileType } from '../Enums/FileType';

interface TestFileUploaderProps {
  testDetails: Test
  acceptedFileType: string
  fileUse: FileUse
  onSuccessfulUpload: (test: Test) => Promise<void>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1)
            },
        }
    }),
);

export default function TestFileUploader(props: TestFileUploaderProps) {

  const classes = useStyles();

  const [dropZoneDisabled, setDropZoneDisabled] = React.useState(false);

  const [downloadDisabled, setDownloadDisabled] = React.useState(false);

  const snackRef = useRef<SnackRef>(null);

  var fileName = props.fileUse === FileUse.Source ? props.testDetails.sourceFileName : props.testDetails.outputFileName;

  var acceptedFileType = props.acceptedFileType === FileType.EXCEL ? ".xls,.xlsx,.xlsm,.csv" : "."+props.acceptedFileType;

  const fileSelected = async (files: FileWithPath[]) => {
    setDropZoneDisabled(true);
    var upload = files[0]
    if (upload != null) {
      var data: FileData = {
        fileName: upload.name,
        fileUse: props.fileUse,
        suiteID: props.testDetails.suiteID,
        testID: props.testDetails.testID
      }

      const jsonData: string = JSON.stringify(data);

      var formData = new FormData();
      formData.append('data', jsonData);
      formData.append('file', upload);

      if (props.fileUse === FileUse.Source) {
        props.testDetails.sourceFileName = upload.name;
      }
      else {
        props.testDetails.outputFileName = upload.name;
      }

      var response = await uploadFile(formData);

      let severity: Color = 'error';
      if (response.success) {
        severity = 'success';
        props.onSuccessfulUpload(props.testDetails);
      }

      if (snackRef.current) {
        snackRef.current.showSnack(response.message, severity);
      }
    }
    setDropZoneDisabled(false);
  };

  const downloadFile = async () => {
    setDownloadDisabled(true);
    try {
      var response = await translateFile(props.testDetails.suiteID.toString(), props.testDetails.testID);
      
      var translatedExtension = props.testDetails.outputType === FileType.EXCEL ? "xlsx" : props.testDetails.outputType.toLowerCase();
      var translatedType = props.testDetails.outputType === FileType.EXCEL ? `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` : `text/${props.testDetails.outputType.toLowerCase()}`;

      if (response.success){
        const element = document.createElement("a");
        var blobData;

        if (props.testDetails.outputType === FileType.EXCEL)
        {
          const byteCharacters = atob(response.message);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          blobData = new Uint8Array(byteNumbers);
        }
        else{
          blobData = response.message;
        }

        const file = new Blob([blobData], {type: translatedType});
        element.href = URL.createObjectURL(file);
        element.download = `${fileName!.substr(0, fileName!.lastIndexOf('.'))}-translated.${translatedExtension}`;
        element.click();
      } 
      else if (snackRef.current)
      {
        snackRef.current.showSnack("Unable to translate file. Run test to see more information", 'error');
      }
    }
    finally
    {
      setDownloadDisabled(false);
    }
  }

  return (
    <div className={classes.root}>
      Current File: {fileName || 'No File Uploaded'}
      
      {fileName && props.fileUse === FileUse.Source && props.acceptedFileType !== FileType.EXCEL &&
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={(event) => { event.stopPropagation(); downloadFile(); }}
          disabled={downloadDisabled}
        >
          Download Translated {props.testDetails.outputType.toUpperCase()}
        </Button>
      }

      <Dropzone accept={acceptedFileType} onDrop={fileSelected} multiple={false} disabled={dropZoneDisabled}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drop files here, or click to upload a new file.</p>
          </div>
        )}
      </Dropzone>
      <Snack ref={snackRef} />
    </div>
  )
}