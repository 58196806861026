import { ActionType } from "../Enums/ActionsType";
import Action from "../Interfaces/Action";
import APIResponse from "../Interfaces/APIResponse";
import Test from "../Interfaces/Test";
import env from '../EnvironmentVariables';
import AuthHandler from "./Auth";
import TestResult from "../Interfaces/TestResult";
import TestSuite from "../Interfaces/TestSuite";

const saveTest = async (test: Test, dispatch: React.Dispatch<Action>, saveType: ActionType) => {
  const jsonData: string = JSON.stringify(test);
  var auth = await AuthHandler.getAuth();
  const token = await auth.getTokenSilently();
  try {
    var response = await fetch(env.apiBaseUrl + '/SaveTest', {
      method: 'POST',
      body: jsonData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": env.apimSubscriptionKey!
      }
    });

    if (response.status === 200) {
      dispatch({ type: saveType, payload: test })
    }
  } catch (error) {
    console.log(error.message);
  }
}

const runTest = async (test: Test): Promise<TestResult> => {
  var auth = await AuthHandler.getAuth();
  const token = await auth.getTokenSilently();
  var response = await fetch(env.apiBaseUrl + `/Suite/${test.suiteID}/RunTest/${test.testID}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      "Ocp-Apim-Subscription-Key": env.apimSubscriptionKey!
    }
  });

  if (response.status === 200) {
    return await response.json();
  }

  var failedResult: TestResult = {
    results: [{
      message: "An error has occured, unable to run test.",
      actualNode: "",
      actualValue: "",
      attributeName: "",
      expectedNode: "",
      expectedValue: ""
    }],
    success: false
  }

  return failedResult;
}

const runSingleFileTest = async (test: Test): Promise<TestResult> => {
  var auth = await AuthHandler.getAuth();
  const token = await auth.getTokenSilently();
  var response = await fetch(env.apiBaseUrl + `/Suite/${test.suiteID}/RunSingleFileTest/${test.testID}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      "Ocp-Apim-Subscription-Key": env.apimSubscriptionKey!
    }
  });
  
  if (response.status === 200) {
    return await response.json();
  }

  var failedResult: TestResult = {
    results: [{
      message: "An error has occured, unable to run test.",
      actualNode: "",
      actualValue: "",
      attributeName: "",
      expectedNode: "",
      expectedValue: ""
    }],
    success: false
  }

  return failedResult;
}

const getAllTests = async (suiteID: number, dispatch: React.Dispatch<Action>, setTestsFetched: React.Dispatch<React.SetStateAction<boolean>>) => {
  try {
    var auth = await AuthHandler.getAuth();
    const token = await auth.getTokenSilently();
    var response = await fetch(env.apiBaseUrl + `/Suite/${suiteID}/GetTests`, {
      method: 'Get',
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": env.apimSubscriptionKey!
      }
    });

    if (response.status === 200) {
      var tests: Test[] = await response.json();
      tests.forEach(test => (dispatch({ type: ActionType.Add, payload: test })));
    }

    setTestsFetched(true);
  } catch (error) {
    console.log(error.message);
  }
}

const deleteTest = async (test: Test, dispatch: React.Dispatch<Action>): Promise<APIResponse> => {
  try {
    var auth = await AuthHandler.getAuth();
    const token = await auth.getTokenSilently();
    var response = await fetch(env.apiBaseUrl + `/Suite/${test.suiteID}/DeleteTest/${test.testID}`, {
      method: 'Delete',
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": env.apimSubscriptionKey!
      }
    });

    if (response.status === 200) {
      dispatch({ type: ActionType.Delete, payload: test });
      return {
        success: true,
        message: 'Successfully Deleted Test.'
      };
    }
  } catch (error) {
    console.log(error.message);
  }
  return {
    success: false,
    message: 'Unable to Delete Test.'
  };
}

const uploadFile = async (formData: FormData): Promise<APIResponse> => {
  try {
    var auth = await AuthHandler.getAuth();
    const token = await auth.getTokenSilently();
    var response = await fetch(env.apiBaseUrl + '/UploadFile', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": env.apimSubscriptionKey!
      }
    });

    var responseMessage = await response.json();
    return {
      success: response.status === 200,
      message: responseMessage
    };
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: "Error Uploading File."
    };
  }
}

const translateFile = async (suiteID: string, testID: string): Promise<APIResponse> => {
  try {
    var auth = await AuthHandler.getAuth();
    const token = await auth.getTokenSilently();
    var response = await fetch(env.apiBaseUrl + `/Suite/${suiteID}/DownloadTranslatedFile/${testID}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": env.apimSubscriptionKey!
      }
    });

    var responseMessage = await response.text();
    return {
      success: response.status === 200,
      message: responseMessage
    };
  } catch (error) {
    console.log(error.message)
    return {
      success: false,
      message: "Error downloading file"
    };
  }
}

const getAllSuites = async (dispatch: React.Dispatch<React.SetStateAction<TestSuite[]>>) => {
  try {
    var auth = await AuthHandler.getAuth();
    const token = await auth.getTokenSilently();
    var response = await fetch(env.apiBaseUrl + '/Suite', {
      method: 'Get',
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": env.apimSubscriptionKey!
      }
    });

    if (response.status === 200) {
      var suites: TestSuite[] = await response.json();
      dispatch(suites);
    }
  } catch (error) {
    console.log(error.message);
  }
}

export { saveTest, getAllTests, deleteTest, uploadFile, runTest, runSingleFileTest, translateFile, getAllSuites };