import { makeStyles, Theme, createStyles, Paper, TableContainer, Table, TableHead, TableRow, TableCell, Grid, TableBody } from "@material-ui/core";
import React from "react";
import Result from "../Interfaces/Result";

interface TestPanelResultsProps {
    suiteID: number;
    testID: string;
    results?: Result[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        formControl: {
            margin: theme.spacing(1),
        },
        table: {
            
        }
    })
);

export default function ResultsPanel(props: TestPanelResultsProps) {
    const classes = useStyles();

    return (
        <Paper className={classes.root} >
            <p>Results</p>
            <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Attribute Name</TableCell>
                            <TableCell>Expected Value</TableCell>
                            <TableCell>Actual Value</TableCell>
                            <TableCell>Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.results ? props.results.map((r, i) => (
                            <TableRow key={i}>
                                <TableCell>{r.attributeName}</TableCell>
                                <TableCell>{r.expectedValue}</TableCell>
                                <TableCell>{r.actualValue}</TableCell>
                                <TableCell>{r.message}</TableCell>
                            </TableRow>
                        )): null}
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
        </Paper>
    )
}