import React from 'react';
import { makeStyles, createStyles, Theme, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import { ResultValue } from './Enums/ResultValue';

const theme = createMuiTheme({
    palette: {
        primary: green,
        secondary: red
    }
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1)
            },
        },
    }),
);

type ResultPanelProps = {
    success: ResultValue
};

export default function ResultPanel({ success }: ResultPanelProps) {
    const classes = useStyles();
    let panel: JSX.Element;

    if (success === ResultValue.Pass) {
        panel = <Chip
            label="Pass"
            variant="outlined"
            color="primary"
        />
    } else if (success === ResultValue.Fail) {
        panel = <Chip
            label="Failures"
            icon={<ErrorOutline />}
            variant="outlined"
            color="secondary"
        />
    } else {
        panel = <Chip
            label="Unknown"
            variant="outlined"
        />
    }

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
                {panel}
            </div>
        </MuiThemeProvider>
    );
}