import React, { useImperativeHandle, forwardRef, Ref } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface SnackRef {
    showSnack: (message: string, severity: Color) => void
}

const Snack = forwardRef(( props, ref: Ref<SnackRef>) => {
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState("Success");
    const [snackSeverity, setSnackSeverity] = React.useState<Color>("success");

    useImperativeHandle(ref, () => ({ showSnack }));

    const showSnack = (message: string, severity: Color) => {
        setSnackMessage(message);
        setSnackSeverity(severity);
        setSnackOpen(true);
    }

    const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };    

    return (
        <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={snackSeverity}>
                {snackMessage}
            </Alert>
        </Snackbar>
    )

});

export default Snack;
