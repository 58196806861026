import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TestSuite from "../Interfaces/TestSuite";
import FolderIcon from "@material-ui/icons/Folder";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ResultPanel from "../ResultPanel";
import { conversionCodeDescription } from "../Enums/ConversionCode";
import { getAllSuites } from "../Handlers/APIHandler";


export default function TestSuiteList() {

  const [suites, setSuites] = useState<TestSuite[]>([]);

  useEffect(() => {
    getAllSuites(setSuites);
  }, [])

  return (<div className="SuiteList">

    <List>
      {
        suites.map(suite =>
          <ListItem button key={suite.suiteID} component={Link} to={{
            pathname: "/Tests/" + suite.suiteID,
            state: suite
          }
          }>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={suite.suiteName}
              secondary={conversionCodeDescription(suite.testingProject)}
            />
            <ListItemSecondaryAction>
              <ResultPanel success={suite.result} />
            </ListItemSecondaryAction>
          </ListItem>)
      }
    </List>
  </div>);
}