import React from 'react';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles, Theme, createStyles, MenuItem } from '@material-ui/core';
import { FileType } from '../Enums/FileType'
import { FileUse } from '../Enums/FileUse';
import Test from '../Interfaces/Test';
import TestFileUploader from './TestFileUploader'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        formControl: {
            margin: theme.spacing(1),
            width: '25ch',
        },
    }),
);

interface TestPanelSettingsProps {
    testDetails: Test
    onSuccessfulUpload: (test: Test) => Promise<void>
    onSourceTypeChange: (sourceFileType: FileType) => void
    onExpectedTypeChange: (expectedFileType: FileType) => void
}

export default function (props: TestPanelSettingsProps) {
    const classes = useStyles();

    const handleSourceFiletypeChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        props.onSourceTypeChange(event.target.value as FileType);
    }

    const handleExpectedFiletypeChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        props.onExpectedTypeChange(event.target.value as FileType);
    }

    return (
        <Paper className={classes.root}>
            <p>Test Settings:</p>
            <div>
                <FormControl variant="outlined" className={classes.formControl} size="small">
                    <InputLabel id="sourceFileTypeLabel">Source File Type</InputLabel>
                    <Select
                        labelId="sourceFileTypeLabel"
                        id="sourceFileType"
                        defaultValue={props.testDetails.sourceType}
                        label="Source File Type"
                        onChange={handleSourceFiletypeChange}
                    >
                        {Object.values(FileType).map((fileTypeValue) => 
                            fileTypeValue !== FileType.EXCEL ? 
                                <MenuItem key={fileTypeValue} value={fileTypeValue}>{fileTypeValue}</MenuItem> : 
                                null
                            
                        )}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} size="small">
                    <TestFileUploader
                        acceptedFileType={props.testDetails.sourceType}
                        testDetails={props.testDetails}
                        fileUse={FileUse.Source}
                        onSuccessfulUpload={props.onSuccessfulUpload}></TestFileUploader>
                </FormControl>

            </div>
            {props.testDetails.isComparison &&
                <div>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="outputFileTypeLabel">Expected File Type</InputLabel>
                        <Select
                            labelId="outputFileTypeLabel"
                            id="outputFileType"
                            defaultValue={props.testDetails.outputType}
                            label="Expected File Type"
                            onChange={handleExpectedFiletypeChange}
                        >
                            {Object.values(FileType).map((fileTypeValue) =>
                                <MenuItem key={fileTypeValue} value={fileTypeValue}>{fileTypeValue}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <TestFileUploader
                            acceptedFileType={props.testDetails.outputType}
                            testDetails={props.testDetails}
                            fileUse={FileUse.Expected}
                            onSuccessfulUpload={props.onSuccessfulUpload}></TestFileUploader>
                    </FormControl>
                </div>}
        </Paper>
    )
}