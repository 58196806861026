export class EnvironmentVariables {
    public apiBaseUrl: string | undefined;
    public auth0Domain: string | undefined;
    public auth0ClientId: string | undefined;
    public apimSubscriptionKey: string | undefined;
}

const envVariables: EnvironmentVariables = {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL as string,
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENTID as string,
    apimSubscriptionKey: process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY as string
}
export default envVariables;