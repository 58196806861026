import env from '../EnvironmentVariables';
import { Auth0Client } from '@auth0/auth0-spa-js'
import createAuth0Client from '@auth0/auth0-spa-js'

export default class AuthHandler {
    static auth: Auth0Client | null = null;

    static async getAuth() {
        if (AuthHandler.auth == null) {
            AuthHandler.auth = await createAuth0Client({
                domain: env.auth0Domain!,
                client_id: env.auth0ClientId!,
                redirect_uri: window.location.origin,
                audience: 'framework-testingtool-api',
                scope: 'write:tests'
            })
        }
        return this.auth!;
    }
}