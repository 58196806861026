import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { ResultValue } from '../Enums/ResultValue';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        formControl: {
            margin: theme.spacing(1),
            width: '25ch',
        },

    }),
);

interface TestPanelDetailsProps {
    testID: string;
    gtin?: string;
    expectedResult: ResultValue;
    onGTINChange: (gtin: string) => void,
    onExpectedResultChange: (expectedResult: ResultValue) => void
}

export default function (props: TestPanelDetailsProps) {
    const classes = useStyles();

    const handleGTINChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        props.onGTINChange(event.target.value as string)
    }

    const handleExpectedResultChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        props.onExpectedResultChange(event.target.value as ResultValue)
    }

    return (
        <Paper className={classes.root}>
            <p>Test Details:</p>
            <div>
                <TextField
                    className={classes.formControl}
                    label="GTIN"
                    defaultValue={props.gtin}
                    id={"gtin_"+props.testID}
                    variant="outlined"
                    size="small" 
                    onBlur={handleGTINChange}/>
            </div>
            <div>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Expected Result</FormLabel>
                    <RadioGroup row name="position" value={props.expectedResult} onChange={handleExpectedResultChange}>
                        <FormControlLabel
                            value={ResultValue.Pass}
                            control={<Radio />}
                            label="Pass"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={ResultValue.Fail}
                            control={<Radio />}
                            label="Fail"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>
            </div>
        </Paper>
    )
}