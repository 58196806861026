import { Auth0Client } from '@auth0/auth0-spa-js';
import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import AuthHandler from './Handlers/Auth';
import TestSuiteList from './TestSuite/TestSuiteList';
import TestSuitePage from './TestSuite/TestSuitePage';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


export default function App() {

  const [auth, setAuth] = useState<Auth0Client | null>(null);

  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    async function getAuth() {
      const auth = await AuthHandler.getAuth();
      setAuth(auth);
      setAuthenticated(await auth.isAuthenticated());
    }
    getAuth();
  }, [isAuthenticated])

  const login = async () => {
    await auth!.loginWithPopup({ redirect_uri: window.location.origin });
    setAuthenticated(await auth!.isAuthenticated());
  }

  return (
    <div>
      <p>Testing Tool</p>
      {!isAuthenticated && (<Button onClick={() => login()}>Log In</Button>)}
      {isAuthenticated && (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={TestSuiteList} />
            <Route exact path="/packages/frameworktestingtool/app/index.html" component={TestSuiteList} />            
            <Route path="/Tests/:id" component={TestSuitePage} />
          </Switch>
        </BrowserRouter>
      )}
    </div>
  )
}