import React, { useReducer, useRef, useEffect, useState } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Color } from '@material-ui/lab/Alert';
import { v1 as uuidv1 } from 'uuid';
import { ActionType } from '../Enums/ActionsType';
import { conversionCodeDescription } from '../Enums/ConversionCode';
import { FileType } from '../Enums/FileType';
import { ResultValue } from '../Enums/ResultValue';
import { saveTest, deleteTest, getAllTests, runTest, runSingleFileTest } from '../Handlers/APIHandler';
import Action from '../Interfaces/Action';
import APIResponse from '../Interfaces/APIResponse';
import Test from '../Interfaces/Test';
import TestPanel from '../Test/TestPanel';
import TestSuite from '../Interfaces/TestSuite';
import ErrorPage from '../ErrorPage';
import ResultPanel from '../ResultPanel'
import Snack, { SnackRef } from '../Snack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    body: {
      margin: '1em'
    }
  }),
);

function testListReducer(state: Test[], action: Action): Test[] {
  switch (action.type) {
    case ActionType.Add:
      {
        if (!state.find((test) => test.testID === action.payload.testID)) {
          return [...state,
          action.payload];
        }
        return state;
      }
    case ActionType.Update:
      {
        return state.map((t) => {
          if (t.testID !== action.payload.testID) {
            return t
          }
          return action.payload;
        });
      }
    case ActionType.Delete:
      {
        return state.filter((test) => test.testID !== action.payload.testID);
      }
    default:
      {
        return state;
      }
  }
}

export default function TestSuitePage(props: RouteComponentProps<{}, StaticContext, TestSuite>) {
  const classes = useStyles();

  const testSuite = props.location.state;

  const [tests, dispatch] = useReducer(testListReducer, []);

  const [testsLoaded, setTestsLoaded] = useState(false);
  const [runDisabled, setRunDisabled] = useState(false);

  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (testSuite !== undefined) {
      getAllTests(testSuite.suiteID, dispatch, setTestsLoaded);
    }
  }, [testSuite])

  function calculateSuiteState() {
    if (!tests.length || tests.some(t => t.actualResult === ResultValue.Unknown)) {
      return ResultValue.Unknown;
    }
    return tests.every((t) => {
      return t.actualResult === t.expectedResult
    }) ? ResultValue.Pass : ResultValue.Fail
  }

  const handleCreateTest = async () => {
    if (testSuite !== undefined) {
      var newTest: Test = {
        suiteID: testSuite.suiteID,
        testID: uuidv1(),
        testName: "New Test " + (tests.length + 1),
        expectedResult: ResultValue.Pass,
        actualResult: ResultValue.Unknown,
        sourceType: FileType.JSON,
        outputType: FileType.XML,
        createdDate: new Date(),
        isComparison: true
      }
      setFilter("");
      saveTest(newTest, dispatch, ActionType.Add);
    }
  }

  const handleUpdateTest = async (test: Test) => {
    saveTest(test, dispatch, ActionType.Update);
  }

  const snackRef = useRef<SnackRef>(null);

  const handleDeleteTest = async (test: Test): Promise<APIResponse> => {
    var response = await deleteTest(test, dispatch);
    var severity: Color = response.success ? 'success' : 'error';
    if (snackRef.current) {
      snackRef.current.showSnack(response.message, severity);
    }
    return response;
  }

  const handleUploadFile = async (test: Test) => {
    dispatch({ type: ActionType.Update, payload: test });
  }

  const handleRunTest = async (test: Test) => {
    var testResult = test.isComparison ? await runTest(test) : await runSingleFileTest(test);
    test.actualResult = testResult.success ? ResultValue.Pass : ResultValue.Fail;
    test.testResults = testResult.results;
    test.lastRunDate = new Date();
    handleUpdateTest(test);
  }

  const runAllTests = async () => {
    setRunDisabled(true);
    try {
      await Promise.all(tests.map(async (test) => {
        await handleRunTest(test);
      }));
    }
    finally {
      setRunDisabled(false);
    }
  }

  if (testSuite === undefined) {
    return (<ErrorPage />)
  }

  return (
    <div className="Suite">
      <div><div className="App-header">
      </div>
        <div className={classes.body}>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <form className={classes.root} noValidate autoComplete="off">
                <div>
                  <TextField
                    label="Project Name"
                    id="name"
                    variant="outlined"
                    size="small"
                    defaultValue={testSuite.suiteName}
                    InputProps={{
                      readOnly: true
                    }} />
                </div>
                <div>
                  <TextField
                    label="Conversion / Mapping Code"
                    defaultValue={conversionCodeDescription(testSuite.testingProject)}
                    id="code"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      readOnly: true
                    }} />
                </div>
              </form>
              <div>
                <Button variant="contained" component={Link} to="/">Return to test list</Button>
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              Current State:
            <ResultPanel success={calculateSuiteState()} />
            </Grid>
            <Grid container>
              <Grid item xs={4} className={classes.root}>
                <Button variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleCreateTest}>
                  Add New Test
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PlayArrowIcon />}
                  onClick={runAllTests}
                  disabled={runDisabled}>
                  Run all tests
              </Button>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Filter Tests"
                  variant="outlined"
                  size="small"
                  value={filter}
                  onChange={e => setFilter(e.target.value)} />
              </Grid>
            </Grid>
          </Grid>
          <List>
            {testsLoaded ?
              (tests.length > 0 ? tests
                .sort((t1, t2) => new Date(t2.createdDate).getTime() - new Date(t1.createdDate).getTime())
                .map(test => test.testName.toUpperCase().includes(filter.toUpperCase()) && (
                  <ListItem key={test.testID}>
                    <TestPanel
                      testDetails={test}
                      onChange={handleUpdateTest}
                      onDelete={handleDeleteTest}
                      onSuccessfulUpload={handleUploadFile}
                      onRunTest={handleRunTest}
                      allRunDisabled={runDisabled} />
                  </ListItem>
                )) : <h1>No Tests Exist</h1>) :
              <h1>Loading Tests</h1>}
          </List>
          <Snack ref={snackRef} />
        </div></div>
    </div>
  );
}