enum ConversionCode {
    PDNA = "PDNA",
    Salsify = "Salsify",
    Beiersdorf = "Beiersdorf"
}

const conversionCodeDescription = (code: ConversionCode) : string => {

    switch(code){
        case ConversionCode.PDNA:
            return "Prod - pDNA Mappings"
        case ConversionCode.Salsify:
            return "Prod - Salsify Mappings"
        case ConversionCode.Beiersdorf:
            return "Prod - Beiersdorf Mappings"
        default:
            return "Unknown code"
    }
}

export {ConversionCode, conversionCodeDescription}